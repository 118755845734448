@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@200;400;500&display=swap');

*,
body {
  font-family: "Outfit", sans-serif;
  scrollbar-width: 10rem;
}

.card-font p{
    font-family: "Lexend";
    font-weight: 500;
}

.fillWhite {
  fill: white;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
